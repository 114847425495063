import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  if (document.querySelector('[data-about-slider]')) {
    const aboutSlider = tns({
      container: document.querySelector('[data-about-slider]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
    });

    bindArrows('[data-about-slider-arrow-left]', '[data-about-slider-arrow-right]', aboutSlider);
  }
}

window.addEventListener('load', () => initSliders());