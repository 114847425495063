import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  if (document.querySelector('[data-about-index-slider]')) {
    const aboutSlider = tns({
      container: document.querySelector('[data-about-index-slider]'),
      items: 4,
      slideBy: 3,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
      responsive: {
        0: {
          disable: true,
        },
        768: {
          disable: true,
        },
        1241: {
          disable: false,
          items: 4,
        },
      },
    });

    bindArrows('[data-about-index-arrow-left]', '[data-about-index-arrow-right]', aboutSlider);
  }
}

window.addEventListener('load', () => initSliders());