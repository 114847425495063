import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  const slidersContainer = document.querySelector('[data-partners-sliders]');
  if (slidersContainer) {
    const imagesSlider = tns({
      container: slidersContainer.querySelector('[data-partners-images-slider]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      rewind: true,
    });

    const descriptionSlider = tns({
      container: slidersContainer.querySelector('[data-partners-description-slider]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      rewind: true,
      mode: 'gallery',
    });

    if (window.innerWidth > 767) {
      bindArrows('[data-partners-work-with-us-bottom-arrow-left]', '[data-partners-work-with-us-bottom-arrow-right]', imagesSlider);
    } else {
      bindArrows('[data-partners-work-with-us-bottom-arrow-left-mobile]', '[data-partners-work-with-us-bottom-arrow-right-mobile]', imagesSlider);
    }

    const syncSliders = function (info) {
      if (info.container.hasAttribute('data-partners-images-slider') && !info.container.hasAttribute('data-blocked')) {
        info.container.setAttribute('data-blocked', '');
        descriptionSlider.goTo(info.index);
        info.container.removeAttribute('data-blocked');
      } else if (info.container.hasAttribute('data-partners-description-slider') && !info.container.hasAttribute('data-blocked')) {
        info.container.setAttribute('data-blocked', '');
        imagesSlider.goTo(info.index);
        info.container.removeAttribute('data-blocked');
      }
    };

    imagesSlider.events.on('transitionStart', syncSliders);
    descriptionSlider.events.on('transitionStart', syncSliders);
  }
}

window.addEventListener('load', () => initSliders());