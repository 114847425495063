import Jax from "../../components/jax/jax";

class Filter {
  constructor(element) {
    this.form = element;
    this.opener = element.querySelector('[data-filter-opener]');
    this.header = document.querySelector('[data-header]');
    this.headerBottom = this.header.querySelector('[data-header-bottom]');
    this.resetButton = element.querySelector('[data-filter-reset]');
    this.submitButton = element.querySelector('[data-filter-submit]');
    this.checkboxes = element.querySelectorAll('input[type="checkbox"]');
    this.mobileWidth = 767;
    this.tabletWidth = 1279;
    this.submitDisabled = false;
    this.init();
  }

  init() {
    this.bindOpener();
    this.bindSubmit();
    this.bindCheckboxes();
    this.bindButtons();
  }

  bindButtons() {
    const instance = this;
    this.resetButton.addEventListener('click', (e) => {
      e.preventDefault();
      instance.submitDisabled = true;
      instance.checkboxes.forEach((input) => {
        input.checked = false;
      });
      instance.submitDisabled = false;
      instance.request(instance.form);
      if (this.isTabletDown()) {
        instance.closeFilter();
      }
    });

    this.submitButton.addEventListener('click', (e) => {
      e.preventDefault();
      instance.request(instance.form);
      instance.closeFilter();
    });
  }

  bindCheckboxes() {
    const instance = this;
    this.checkboxes.forEach((input) => {
      input.addEventListener('change', (e) => {
        if (!instance.isTabletDown() && !instance.submitDisabled) {
          instance.request(instance.form);
        }
      });
    });
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.request(instance.form);
    });
  }

  request(form) {
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then((html) => {
      const page = document.createElement('div');
      page.innerHTML = html;
      const newData = page.querySelector('[data-catalog-content]');
      const currentData = document.querySelector('[data-catalog-content]');
      currentData.innerHTML = newData.innerHTML;
      document.dispatchEvent(new Event('DOMContentMutated'));
    });
  }

  bindOpener() {
    const instance = this;
    this.opener.addEventListener('click', (e) => {
      e.preventDefault();
      if (instance.form.classList.contains('_open')) {
        instance.closeFilter();
      } else {
        instance.openFilter();
      }
    });
  }

  closeFilter() {
    this.form.classList.remove('_open');
    this.form.style.top = 0;
    document.body.style.overflow = '';
    document.body.style.height = '';
  }

  openFilter() {
    let offsetTop = 0;
    if (!this.isMobile()) {
      offsetTop = this.headerBottom.getBoundingClientRect().height;
      this.header.dispatchEvent(new CustomEvent('ToTop'));
    } else {
      this.header.dispatchEvent(new CustomEvent('ToBottom'));
      offsetTop = this.header.getBoundingClientRect().height;
    }
    this.form.style.top = `${offsetTop}px`;
    this.form.classList.add('_open');
    document.body.style.overflow = 'hidden';
    document.body.classList.add('_open-filter');
    document.body.style.height = '100%';
  }

  isMobile() {
    return window.innerWidth <= this.mobileWidth;
  }

  isTabletDown() {
    return window.innerWidth <= this.tabletWidth;
  }
}

function initFilter() {
  const element = document.querySelector('[data-catalog-filter]');
  if (element) {
    const filter = new Filter(element);
  }
}

document.addEventListener('DOMContentLoaded', () => initFilter());