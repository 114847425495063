function checkHeight() {
  const textWrapper = document.querySelector('[data-text-wrapper]');
  const text = document.querySelector('[data-text-contain]');
  const showLink = document.querySelector('[data-catalog-show-more-button]');

  if (showLink) {
    if ((textWrapper && text) && (text.offsetHeight <= textWrapper.offsetHeight)) {
      showLink.classList.add('_hide');
      showLink.parentNode.parentNode.classList.add('_show-more-button-hidden');
    } else {
      showLink.classList.remove('_hide');
      showLink.parentNode.parentNode.classList.remove('_show-more-button-hidden');
    }
  }
}

document.addEventListener('DOMContentLoaded', checkHeight());