class AutoPagination {
  links = [];
  constructor() {
    this.findLinks();
    this.bind();
    this.detectLinks();
  }

  bind() {
    this.bindMutated();
    this.bindScroll();
  }

  bindMutated() {
    const autoPaginator = this;
    document.addEventListener('DOMContentMutated', (e) => {
      autoPaginator.findLinks();
      autoPaginator.detectLinks();
    });
  }

  bindScroll() {
    const autoPaginator = this;
    window.addEventListener('scroll', (e) => {
      autoPaginator.detectLinks();
    });
  }

  detectLinks() {
    const toTriggerIndexes = [];
    this.links.forEach((link, index) => {
      const bound = link.getBoundingClientRect();
      if (bound.top - window.innerHeight <= window.innerHeight / 2) {
        toTriggerIndexes.push(index);
      }
    });

    if (toTriggerIndexes.length) {
      this.dispatchLinks(toTriggerIndexes)
    }
  }

  dispatchLinks(indexes) {
    const autoPaginator = this;
    const event = new Event('click');
    indexes.forEach((index) => {
      autoPaginator.links[index].dispatchEvent(event);
      autoPaginator.links.splice(index, 1);
    })
  }

  findLinks() {
    document.querySelectorAll('[data-endless-action]:not([data-initialized])').forEach((link) => {
      link.dataset.initialized = 'true';
      this.links.push(link);
    });
  }
}

function initAutoPagination() {
  const autoPaginator = new AutoPagination();
}

document.addEventListener('DOMContentLoaded', initAutoPagination);
