function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach((mapElement) => {
    const myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    }, {
      searchControlProvider: 'yandex#search',
    });

    const collection = new ymaps.GeoObjectCollection(null, {
      // Запретим появление балуна.
      hasBalloon: false,
    });

    const offices = document.querySelectorAll('[data-office]');

    offices.forEach((office) => {
      const myPlacemark = new ymaps.Placemark([parseFloat(office.dataset.lat), parseFloat(office.dataset.lng)], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: mapElement.dataset.mark,
        // Размеры метки.
        iconImageSize: [50, 64],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-12.5, -34],
      });


      const changedFilialMap = function (placemark) {
        const mapFilial = document.querySelector('[data-office]');
        myMap.setCenter(placemark.geometry.getCoordinates());
        mapFilial.dataset.lat = placemark.geometry.getCoordinates()[0];
        mapFilial.dataset.lng = placemark.geometry.getCoordinates()[1];
        mapFilial.addEventListener('click', () => {
          myMap.setCenter([parseFloat(mapFilial.dataset.lat), parseFloat(mapFilial.dataset.lng)]);
        });
      };

      myMap.geoObjects.add(myPlacemark);

      myPlacemark.events.add(['click'], (e) => {
        changedFilialMap(myPlacemark);
      });

      office.addEventListener('click', () => {
        myMap.setCenter([parseFloat(office.dataset.lat), parseFloat(office.dataset.lng)]);
        changedFilialMap(myPlacemark);
        document.querySelector('[data-map-link]').click();
      });

      myMap.geoObjects.add(collection);
      office.addEventListener('click', () => {
        offices.forEach((el) => {
          el.classList.remove('_active');
        });
        office.classList.add('_active');
        myMap.panTo([parseFloat(office.dataset.lat), parseFloat(office.dataset.lng)], {
          useMapMargin: true,
        });
      });
    });

    window.addEventListener('loaded', () => {
      myMap.container.fitToViewport();
    });
    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
  });
}

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});