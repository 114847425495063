require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/accordion-init');
require('fslightbox');

// require('./header');

require('./field/phone.js');
// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('../vue/app.js');
require('../fonts/Inter/stylesheet.css');
require('./header/sticky');
require('./field/floating');
require('./sliders/sliders');
require('./filter/filter');
require('fslightbox');
require('./lightbox/lightbox');
require('./publications/change-show-all-link');
require('./catalog/definition-show-more-button');
require('./pagination/auto-pagination');