import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  if (document.querySelector('[data-about-certificates]')) {
    const partnersEquipmentSlider = tns({
      container: '[data-about-certificates]',
      items: 4,
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      swipeAngle: false,
      loop: true,
      mouseDrag: true,
      rewind: false,
      autoWidth: true,
      responsive: {
        0: {
          disable: true,
        },
        768: {
          disable: true,
        },
        1280: {
          disable: false,
          gutter: 28,
          fixedWidth: 278,
        },
      },
    });

    bindArrows('[data-about-certificates-arrow-left]', '[data-about-certificates-arrow-right]', partnersEquipmentSlider);
  }
}

window.addEventListener('DOMContentLoaded', initSliders);