import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  if (document.querySelector('[data-partners-equipment-slider]')) {
    const partnersEquipmentSlider = tns({
      container: '[data-partners-equipment-slider]',
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
      responsive: {
        0: {
          edgePadding: 18,
          disable: true,
        },
        768: {
          edgePadding: 40,
        },
        1280: {
          edgePadding: 0,
          items: 3,
          disable: false,
        },
      },
    });

    bindArrows('[data-partners-equipment-arrow-left]', '[data-partners-equipment-arrow-right]', partnersEquipmentSlider);
  }
}

window.addEventListener('load', () => initSliders());