import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  const productMainSliderElement = document.querySelector('[data-product-main-slider]');
  const productMiniSliderElement = document.querySelector('[data-product-mini-slider]');
  if (productMainSliderElement) {
    const productMainSlider = tns({
      container: productMainSliderElement,
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
    });

    if (productMiniSliderElement) {
      const productMiniSlider = tns({
        container: productMiniSliderElement,
        slideBy: 1,
        nav: false,
        speed: 700,
        controls: false,
        loop: false,
        responsive: {
          0: {
            gutter: 10,
            fixedWidth: 60,
          },
          768: {
            gutter: 12,
            fixedWidth: 75,
          },
          1279: {
            items: 4,
            gutter: 14,
          },
        },
      });

      productMiniSliderElement.querySelectorAll('[data-mini-link]').forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const index = parseInt(link.dataset.miniLink, 10);
          productMainSlider.goTo(index);
        });
      });

      productMainSlider.events.on('transitionStart', (info) => {
        productMiniSlider.goTo(info.index);
        const currentActive = productMiniSliderElement.querySelector('[data-mini-item]._active');
        const selectedItem = productMiniSliderElement.querySelector(`[data-mini-item="${info.index}"]`);
        if (currentActive) {
          currentActive.classList.remove('_active');
        }
        if (selectedItem) {
          selectedItem.classList.add('_active');
        }
      });

      bindArrows('[data-product-slider-arrow-left]', '[data-product-slider-arrow-right]', productMainSlider);
    }
  }
}

window.addEventListener('load', () => initSliders());