//
class StickyHeader {
  config = {
    enableWidth: 767,
    hamburgerSelector: '', // Если есть гамбурге цепляем его селектор сюда
    menuSelector: '', // Если нужно на меню вешать класс от гамбургера - сюда пихаем селектор его
    headerTopSelector: '', // Часть шапки, которая уйдет в небытие при скролле на десктопе и таблетке
    catalogButtonSelector: '',
  };

  enableMobile = false;
  header = undefined;
  hamburger = undefined;
  menu = undefined;
  headerTop = undefined;
  catalogButton = undefined;

  lastScrollPosition = 0;
  currentOffsetHeader = 0;
  currentHeaderHeight = 0;
  currentHeaderTopHeight = 0;

  menuLinks = [];
  tabletParentLinks = []; // Родительские ссылки для таблетки, биндится клик на открытие/закрытие того сего

  categoryLinks = []; // Ссылки на категории в каталоге, биндиться на mouseover для показа изображения

  constructor(selector, config) {
    this.config = Object.assign({}, this.config, config);
    this.header = document.querySelector(selector);
    this.hamburger = document.querySelector(this.config.hamburgerSelector);
    this.menu = document.querySelector(this.config.menuSelector);
    this.headerTop = this.header.querySelector(this.config.headerTopSelector);
    this.catalogButton = this.header.querySelector(this.config.catalogButtonSelector);

    this.tabletParentLinks = Array.from(this.header.querySelectorAll('[data-header-tablet-menu] [data-has-child-link]'));

    this.categoryLinks = Array.from(this.header.querySelectorAll('[data-header-catalog-category]'));

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setLastScrollPosition(scrollTop);

    this.init();
  }

  init() {
    this.defineDevice();
    this.defineHeaderHeight();
    this.enableScrollListener();
    this.bindHamburger();
    this.bindCatalogButton();
    this.bindResize();
    this.bindLinks();
    this.bindHeaderEvents();
    this.setStaticObject();
    this.bindCategoryLinks();
    this.checkSticky(true);
  }

  bindHeaderEvents() {
    const instance = this;
    this.header.addEventListener('ToTop', (e) => {
      if (!instance.isMobile()) {
        instance.currentOffsetHeader = instance.currentHeaderTopHeight;
        instance.translateHeader();
      }
    });
    this.header.addEventListener('ToBottom', (e) => {
      if (instance.isMobile()) {
        instance.currentOffsetHeader = 0;
        instance.translateHeader();
      }
    });
  }

  enableScrollListener() {
    const instance = this;
    document.addEventListener('scroll', instance.listener);
  }

  listener(e) {
    if (StickyHeader.instance) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop < 0) {
        return;
      }
      const instance = StickyHeader.instance;
      const lastScrollPosition = instance.lastScrollPosition;

      if (lastScrollPosition < scrollTop) {
        instance.translateHeaderTop(scrollTop - lastScrollPosition);
        instance.checkSticky(true);
      } else {
        instance.translateHeaderBottom(lastScrollPosition - scrollTop);
        instance.checkSticky(false);
      }

      instance.setLastScrollPosition(scrollTop)
    }
  }

  checkSticky(bottomDirection) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const header = StickyHeader.instance.header;
    const activateHeight = this.enableMobile ? this.currentHeaderHeight : this.currentHeaderTopHeight;
    if (bottomDirection && scrollTop > activateHeight && !header.classList.contains('_sticky')) {
      header.classList.add('_sticky');
    } else if (!bottomDirection && scrollTop <= 0 && header.classList.contains('_sticky')) {
      header.classList.remove('_sticky');
    }
  }

  translateHeaderTop(diff) {
    const availOffsetHeight = this.isMobile() ? this.currentHeaderHeight : this.currentHeaderTopHeight;

    if (this.currentOffsetHeader < availOffsetHeight) {
      this.currentOffsetHeader += diff;
      if (this.currentOffsetHeader > availOffsetHeight) {
        this.currentOffsetHeader = availOffsetHeight;
      }

      this.translateHeader();
    }
  }

  translateHeaderBottom(diff) {
    if (this.currentOffsetHeader > 0) {
      this.currentOffsetHeader -= diff;
      if (this.currentOffsetHeader < 0) {
        this.currentOffsetHeader = 0;
      }
      this.translateHeader();
    }
  }

  translateHeader() {
    this.header.style.transform = `translate3D(0, ${-this.currentOffsetHeader}px, 0)`;
  }

  bindHamburger() {
    const instance = this;
    this.hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      if (instance.hamburger.classList.contains('_open')) {
        instance.closeMenu();
      } else {
        instance.openMenu();
      }
    });
  }

  bindLinks() {
    const instance = this;
    this.tabletParentLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        if (this.header.classList.contains('_catalog-open')) {
          instance.closeCatalogMenu();
        }
        instance.tabletParentLinks.forEach((parentLink) => {
          if (parentLink === link) {
            parentLink.parentElement.classList.toggle('_open')
          } else if (parentLink.parentElement.classList.contains('_open')) {
            parentLink.parentElement.classList.remove('_open');
          }
        })
      });
    });
  }

  bindCatalogButton() {
    this.catalogButton.addEventListener('click', (e) => {
      if (window.innerWidth < 1280) {
        e.preventDefault();
        this.header.classList.contains('_catalog-open') ? this.closeCatalogMenu() : this.openCatalogMenu();
        this.tabletParentLinks.forEach((tabletLink) => {
          tabletLink.parentElement.classList.remove('_open');
        })
      }
    });
  }

  bindCategoryLinks() {
    this.categoryLinks.forEach((link) => {
      link.addEventListener('mouseover', (e) => {
        link.parentNode.querySelectorAll('[data-header-catalog-category]').forEach((item) => {
          item.classList.remove('_active')
        });

        link.classList.add('_active');
      });
    });
  }

  openCatalogMenu() {
    this.header.classList.add('_catalog-open');
    this.catalogButton.classList.add('_open');
  }

  closeCatalogMenu() {
    this.header.classList.remove('_catalog-open');
    this.catalogButton.classList.remove('_open');
  }

  openMenu() {
    this.header.classList.add('_open');
    this.hamburger.classList.add('_open');
    if (this.menu) {
      this.menu.classList.add('_open');
    }
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  }

  closeMenu() {
    this.header.classList.remove('_open');
    this.hamburger.classList.remove('_open');
    if (this.menu) {
      this.menu.classList.remove('_open');
    }
    if (!document.body.classList.contains('_open-filter')) {
      document.body.style.overflow = '';
      document.body.style.height = '';
    }
  }

  defineDevice() {
    this.isMobile() ? this.setEnabledMobile() : this.setDisabledMobile();
  }

  setEnabledMobile() {
    this.enableMobile = true;
  }

  setDisabledMobile() {
    this.enableMobile = false;
  }

  isMobile() {
    return window.innerWidth <= this.config.enableWidth;
  }

  bindResize() {
    const instance = this;
    window.addEventListener('resize', (e) => {
      instance.switchCheck();
    });
  }

  switchCheck () {
    this.defineDevice();
    this.defineHeaderHeight();
    this.resetHeaderPosition();
  }

  resetHeaderPosition() {
    this.currentOffsetHeader = 0;
    this.translateHeader();
  }

  setLastScrollPosition(scrollY) {
    this.lastScrollPosition = scrollY;
  }

  setStaticObject() {
    StickyHeader.instance = this;
  }

  defineHeaderHeight() {
    this.currentHeaderHeight = this.header.getBoundingClientRect().height;
    this.currentHeaderTopHeight = this.headerTop.getBoundingClientRect().height;
  }
}

function initHeader() {
  setTimeout(() => {
    try {
      const rootStyles = getComputedStyle(document.documentElement);
      const smallWidth = rootStyles.getPropertyValue('--small-inner-width');
      const header = new StickyHeader('[data-header]', {
        hamburgerSelector: '[data-header-humburger]',
        menuSelector: '[data-header-hamburger-menu]',
        headerTopSelector: '[data-header-top]',
        catalogButtonSelector: '[data-header-catalog-button]',
        enableWidth: smallWidth,
      });
    } catch (e) {
      console.log(e);
    }
  }, getPrepareTimeout());
}


function getPrepareTimeout() {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 2000;
  }
  return timeout;
}

document.addEventListener('DOMContentLoaded', initHeader);