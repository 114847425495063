import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  const menuSliderElement = document.querySelector('[data-menu-slider]');
  if (menuSliderElement) {
    const menuSliderContainer = document.querySelector('[data-menu-slider-container]');
    const menuSlider = tns({
      container: menuSliderElement,
      nav: false,
      controls: false,
      loop: false,
      autoWidth: true,
      speed: 300,
      responsive: {
        0: {
          edgePadding: 18,
          gutter: 8,
        },
        768: {
          edgePadding: 40,
          gutter: 14,
        },
        1279: {
          edgePadding: 0,
        },
      },
      onInit: () => {
        const currentSlide = menuSliderElement.querySelector('[data-activate-link]');
        const slideNumber = parseInt(currentSlide.dataset.menuSlideItem, 10);
        const lastSlide = menuSliderElement.lastElementChild;
        const containerLeft = menuSliderContainer.getBoundingClientRect().left;
        const firstLeft = menuSliderElement.firstElementChild.getBoundingClientRect().left;
        const lastRight = lastSlide.getBoundingClientRect().right;
        const containerRight = menuSliderContainer.getBoundingClientRect().right;

        if (firstLeft < containerLeft || lastRight > containerRight) {
          menuSlider.events.on('indexChanged', (info) => {
            setTimeout(() => {
              const containerRightPosition = menuSliderContainer.getBoundingClientRect().right;
              const lastRightPosition = lastSlide.getBoundingClientRect().right;
              if (info.index === 0) {
                menuSliderContainer.classList.add('_start');
              } else if (lastRightPosition <= containerRightPosition) {
                menuSliderContainer.classList.add('_end');
              } else {
                menuSliderContainer.classList.remove('_start');
                menuSliderContainer.classList.remove('_end');
              }
            }, 500);
          });
        }

        menuSlider.goTo(slideNumber);
      },
    });

    bindArrows('[data-menu-slider-arrow-left]', '[data-menu-slider-arrow-right]', menuSlider);
  }
}

window.addEventListener('load', () => initSliders());