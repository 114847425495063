import {tns} from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

function initSliders() {
  const sliderElement = document.querySelector('[data-hero-slider]');
  if (sliderElement) {
    const slider = tns({
      container: sliderElement,
      items: 1,
      slideBy: 1,
      nav: true,
      speed: 700,
      controls: false,
      rewind: true,
      onInit: () => {
        const container = document.querySelector('[data-hero-slider-container]');
        if (container) {
          container.classList.add('_loaded');
        }
      }
    });

    bindArrows('[data-hero-left]', '[data-hero-right]', slider);
  }
}

window.addEventListener('load', () => initSliders());