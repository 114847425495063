const bindArrows = (left, right, slider) => {
  const leftArrows = document.querySelectorAll(left);
  const rightArrows = document.querySelectorAll(right);

  leftArrows.forEach((leftArrow) => {
    leftArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('prev');
      slider.play();
    });
  });

  rightArrows.forEach((rightArrow) => {
    rightArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('next');
      slider.play();
    });
  });
};

export default bindArrows;